import {
  BusinessAccountAddressDto,
  BusinessAccountAddressesDto,
  BusinessAccountDto,
  BusinessAccountInvoiceInstructionsDto,
  BusinessAccountPoliciesDto,
  BusinessAccountsTermsOfUseDto,
  WalletConversionErrorDto,
} from 'types/dtos/business-account'

import { AddressModel } from 'types/models/address'
import {
  BusinessAccountAddressesModel,
  BusinessAccountInvoiceInstructionsModel,
  BusinessAccountModel,
  BusinessAccountPoliciesModel,
  BusinessAccountsProTermsOfUseModel,
  WalletConversionErrorModel,
} from 'types/models/business-account'

import { transformAddress } from './address'

export const transformBusinessAccountDto = (dto: BusinessAccountDto): BusinessAccountModel => {
  const { id, name, email, nationality, vat, status } = dto

  return {
    id,
    name,
    email,
    nationality,
    vat,
    status,
    entityType: dto.entity_type,
    phoneNumber: dto.phone_number,
    legalName: dto.legal_name,
    legalCode: dto.legal_code,
  }
}

export const transformBusinessAccountPoliciesDto = (
  policies: BusinessAccountPoliciesDto,
): BusinessAccountPoliciesModel => {
  const { terms_and_conditions, return_policy, additional_information } = policies

  return {
    termsAndConditions: terms_and_conditions,
    returnPolicy: return_policy,
    additionalInformation: additional_information,
  }
}

export const transformBusinessAccountAddressDto = (
  address: BusinessAccountAddressDto,
): AddressModel => {
  const { id, name, country_id, country_title_local, city, postal_code, line1, line2 } = address

  return {
    id,
    name,
    countryId: country_id,
    country: country_title_local || '',
    city,
    postalCode: postal_code,
    line1,
    line2,
  }
}

export const transformBusinessAccountAddressModel = (
  address: AddressModel,
): BusinessAccountAddressDto => {
  const { name, countryId, country, city, postalCode, line1, line2 } = address

  return {
    name: name || null,
    country_id: countryId || null,
    country_title_local: country,
    city: city || null,
    postal_code: postalCode || null,
    line1: line1 || null,
    line2: line2 || '',
  }
}

export const transformBusinessAccountAddressesDto = (
  addresses: BusinessAccountAddressesDto,
): BusinessAccountAddressesModel => {
  const { business_address, return_address } = addresses

  return {
    businessAddress: business_address && transformBusinessAccountAddressDto(business_address),
    returnAddress: return_address && transformBusinessAccountAddressDto(return_address),
    isUsingTheSameAddress: !return_address,
  }
}

export const transformBusinessAccountInvoiceInstructionsDto = (
  business_account_invoice_instructions: BusinessAccountInvoiceInstructionsDto,
): BusinessAccountInvoiceInstructionsModel => {
  const {
    buyer: { address, email },
  } = business_account_invoice_instructions

  return {
    address: transformAddress(address),
    email,
  }
}

export const transformWalletConversionError = (
  dto: WalletConversionErrorDto,
): WalletConversionErrorModel => ({
  title: dto.title,
  body: dto.body,
  buttonTitle: dto.button_title,
  buttonUrl: dto.button_url,
})

export const transformBusinessAccountsTermsOfUseDto = (
  dto: BusinessAccountsTermsOfUseDto,
): BusinessAccountsProTermsOfUseModel => ({
  title: dto.title,
  body: dto.body,
  terms_updates: dto.terms_updates,
})
