import { CreateDsaNoticeAppealArgs } from 'types/api/arguments/create-dsa-notice-appeal-args'

export const getDsaNoticeCreateAppealArgs = ({
  threadId,
  message,
  attachments,
  isEnglishAllowed,
}: CreateDsaNoticeAppealArgs) => ({
  thread_id: threadId,
  message,
  attachments,
  english_allowed: isEnglishAllowed,
})
