import { GetNearbyPickupPointsArgs } from 'types/api/arguments/get-nearby-pickup-points-args'

export const getNearbyPickupPointsArgsToParams = ({
  countryCode,
  latitude,
  longitude,
}: GetNearbyPickupPointsArgs) => ({
  country_code: countryCode,
  latitude,
  longitude,
})
