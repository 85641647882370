import { AddShippingAddressArgs } from 'types/api/arguments/add-shipping-address-args'

import { AddressEntryType } from 'constants/address'

export const addShippingAddressArgsToParams = ({ address }: AddShippingAddressArgs) => ({
  entry_type: AddressEntryType.Shipping,
  country_id: address.countryId,
  name: address.name,
  country: address.country,
  city: address.city,
  line1: address.line1,
  line2: address.line2,
  postal_code: address.postalCode,
})
