import { CreatePaymentsAccountArgs } from 'types/api/arguments/create-payments-account-args'

export const createPaymentsAccountArgsToParams = (args: CreatePaymentsAccountArgs) => {
  return {
    payments_account: {
      user_address_id: args.addressId,
      first_name: args.firstName || undefined,
      last_name: args.lastName || undefined,
      birthdate: args.birthdate || undefined,
      ssn_serial: args.ssnNumber || undefined,
      personal_id_number: args.personalIdNumber || undefined,
      nationality: args.nationality ? { code: args.nationality.code } : undefined,
      pep_details: args.pepDetails
        ? {
            type: args.pepDetails.type,
            position: args.pepDetails.position,
            organisation: args.pepDetails.organisation,
            holds_position_from: args.pepDetails.holdsPositionFrom,
            associated_pep_full_name: args.pepDetails.associatedPepFullName,
          }
        : undefined,
    },
  }
}
