import { UpdateUserBundleDiscountsArgs } from 'types/api/arguments/update-user-bundle-discounts-args'

export const updateUserBundleDiscountsArgsToParams = ({
  isEnabled,
  discounts,
}: UpdateUserBundleDiscountsArgs) => ({
  bundle_discount: {
    enabled: isEnabled,
    discounts: discounts?.map(({ fraction, minimalItemCount }) => ({
      fraction,
      minimal_item_count: minimalItemCount,
    })),
  },
})
