import { CreateSupportTicketArgs } from 'types/api/arguments/create-support-ticket-args'

export const getCreateSupportTicketArgs = ({
  faqEntryId,
  ch,
  problemTextId,
  transactionId,
  threadId,
  tempUuid,
  message,
  isEnglishAllowed,
  itemId,
  memberId,
  email,
  helpCenterSessionId,
}: CreateSupportTicketArgs) => ({
  faq_entry_id: faqEntryId,
  ch,
  transaction_id: transactionId,
  thread_id: threadId,
  problem_text_id: problemTextId,
  temp_uuid: tempUuid,
  help_contact_form: {
    message,
    english_allowed: isEnglishAllowed,
    item_id: itemId,
    transaction_id: transactionId,
    member_id: memberId,
    email,
  },
  help_center_session_id: helpCenterSessionId,
})
