import { UpdateFeedbackRatingOptionsArgs } from 'types/api/arguments/update-feedback-rating-options-args'

export const updateFeedbackRatingOptionsArgsToParams = ({
  rating,
  message,
  feedbackStatementParentId,
  feedbackStatementIds,
  finalized,
}: UpdateFeedbackRatingOptionsArgs) => ({
  feedback_ratings: {
    finalized,
    rating,
    comment: message,
    feedback_statement_parent_id: feedbackStatementParentId,
    feedback_statement_ids: feedbackStatementIds,
  },
})
