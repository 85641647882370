import { RegisterCreditCardArgs } from 'types/api/arguments/register-credit-card-args'

import { transformBrowserAttributesModel } from 'data/transformers/browser-attributes'
import { transformCreditCardEncryptedModel } from 'data/transformers/credit-card-encrypted'

export const registerCreditCardArgsToParams = ({
  id,
  brand,
  registrationData,
  encryptedCardDetails,
  isSingleUse,
  browserAttributes,
  source,
  isCobranded,
}: RegisterCreditCardArgs) => ({
  browser_attributes: transformBrowserAttributesModel(browserAttributes),
  credit_card_registration: {
    id,
    registration_data: registrationData,
    encrypted_card_details: encryptedCardDetails
      ? transformCreditCardEncryptedModel(encryptedCardDetails)
      : undefined,
  },
  credit_card: {
    brand,
    single_use: isSingleUse,
    co_branded: isCobranded,
  },
  redirect_params: {
    order_id: source.orderId,
    entity_id: source.entityId,
    entity_type: source.entityType,
  },
})
