import { UpdateTaxpayerFormDataArgs } from 'types/api/arguments/update-taxpayer-form-data-args'

export const updateTaxpayerFormDataArgsToParams = ({
  countryOfTaxResidency,
  firstName,
  lastName,
  birthdate,
  billingAddressId,
  taxIdentificationNumber,
  birthplaceCountry,
  birthplaceCity,
  businessName,
  businessCode,
  secondaryBusinessCode,
  vatNumber,
  businessAddressId,
  businessEstablishmentCountries,
}: UpdateTaxpayerFormDataArgs) => ({
  taxpayer: {
    first_name: firstName,
    last_name: lastName,
    birthdate,
    country_of_tax_residency: countryOfTaxResidency,
    tin: taxIdentificationNumber,
    billing_address_id: billingAddressId,
    birthplace_country: birthplaceCountry,
    birthplace_city: birthplaceCity,
    business_name: businessName,
    business_code: businessCode,
    secondary_business_code: secondaryBusinessCode,
    vat_number: vatNumber,
    business_address_id: businessAddressId,
    business_establishment_countries: businessEstablishmentCountries,
  },
})
