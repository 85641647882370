import { UpdateCurrentUserArgs } from 'types/api/arguments/update-current-user-args'

export const updateCurrentUserArgsToParams = ({
  realName,
  cityId,
  countryId,
  gender,
  birthday,
  about,
  username,
  photoTempUuid,
  isCityVisible,
  isOnHoliday,
  isInternationalTradingEnabled,
  fingerprint,
}: UpdateCurrentUserArgs) => ({
  user: {
    real_name: realName,
    city_id: cityId,
    country_id: countryId,
    gender,
    birthday,
    text_about: about,
    login: username,
    photo_temp_uuid: photoTempUuid,
    user_settings: {
      is_location_public: isCityVisible,
      on_holiday: isOnHoliday,
      international_trading_enabled: isInternationalTradingEnabled,
    },
  },
  fingerprint,
})
