import { addressToParams } from 'data/api/transformers/arguments/address-to-params'
import { documentFileToParams } from 'data/api/transformers/arguments/document-file-to-params'
import { SubmitPaymentsIdentityArgs } from 'types/api/arguments/submit-payments-identity-args'

export const submitPaymentsIdentityArgsToParams = ({
  firstName,
  lastName,
  birthdate,
  nationality,
  ssnSerial,
  personalIdNumber,
  address,
  ubos,
  documents,
}: SubmitPaymentsIdentityArgs) => {
  const transformedDocuments: Array<{
    type: string
    files: Array<{
      identifier: string
      uuid?: string | null
      mediaType?: string | null
      body?: string | null
    }>
  }> = documents?.map(document => documentFileToParams(document)) || []

  return {
    first_name: firstName,
    last_name: lastName,
    nationality,
    birthdate,
    ssn_serial: ssnSerial,
    personal_id_number: personalIdNumber,
    address: addressToParams(address),
    ubos:
      ubos?.map(ubo => ({
        first_name: ubo.firstName,
        last_name: ubo.lastName,
        birthday: ubo.birthday,
        nationality: ubo.nationality,
        birthplace_city: ubo.birthplaceCity,
        birthplace_country: ubo.birthplaceCountry,
        address: addressToParams(ubo.address),
      })) || null,
    documents: transformedDocuments,
  }
}
