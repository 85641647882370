import { HelpCenterFeedbackArgs } from 'types/api/arguments/help-center-feedback-args'

export const sendHelpCenterFeedbackArgsToParams = ({
  expirationDate,
  ticketId,
  rating,
  effort,
  comment,
  digest,
}: HelpCenterFeedbackArgs) => ({
  helpdesk_feedback: {
    ticket_id: ticketId,
    rating,
    effort,
    comment,
  },
  expiration_date: expirationDate,
  digest,
})
