import { UpdateCheckoutDataArgs } from 'types/api/arguments/update-checkout-data-args'

export const updateCheckoutDataArgsToParams = ({
  toAddressId,
  deliveryType,
  creditCardId,
  packageTypeId,
  payInMethodId,
  pickupPointCode,
  rateUuid,
  isOfflineVerificationEnabled,
  isElectronicsVerificationEnabled,
  pointUuid,
  rootRateUuid,
}: UpdateCheckoutDataArgs) => ({
  transaction: {
    shipment: {
      to_address_id: toAddressId,
      delivery_type: deliveryType,
      package_type_id: packageTypeId,
      pickup_point_code: pickupPointCode,
      rate_uuid: rateUuid,
      point_uuid: pointUuid,
      root_rate_uuid: rootRateUuid,
    },
    buyer_debit: {
      pay_in_method_id: payInMethodId,
      external_card_code: creditCardId,
    },
    offline_verification: {
      enabled: isOfflineVerificationEnabled,
    },
    electronics_verification: {
      enabled: isElectronicsVerificationEnabled,
    },
  },
})
