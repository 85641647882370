import { IntellectualPropertyCategory } from 'constants/intellectual-property-infringement'
import { getItemsInText } from 'pages/HelpCenter/IntellectualPropertyInfringement/utils'
import { SendIntellectualPropertyInfringementArgs } from 'types/api/arguments/send-intellectual-property-infringement-args'

export const getIpInfringementParams = ({
  intellectualPropertyCategory,
  rightsConsistOf,
  intellectualPropertyOwner,
  intellectualPropertyName,
  registrationNumber,
  proofOfRights,
  brand,
  description,
  attachments,
  infringingUrlsTextArea,
}: SendIntellectualPropertyInfringementArgs) => {
  const getCategoryDependantFields = () => {
    if (intellectualPropertyCategory === IntellectualPropertyCategory.Other) {
      return { rights_consist_of: rightsConsistOf }
    }

    return {
      owner: intellectualPropertyOwner,
      name: intellectualPropertyName,
      registration_number: registrationNumber,
    }
  }

  const illegalContentUrls = getItemsInText(infringingUrlsTextArea).map(url => ({ url }))

  return {
    category: intellectualPropertyCategory,
    ...getCategoryDependantFields(),
    proof_of_rights: proofOfRights,
    brand: brand?.title,
    description,
    attachments: attachments?.map(attachment => ({ temp_uuid: attachment.id })),
    illegal_contents: illegalContentUrls,
  }
}
