import { UpdateCurrentUserSettingsArgs } from 'types/api/arguments/update-current-user-settings-args'

export const updateCurrentUserSettingsArgsToParams = ({
  isNewsletterSubscribed,
  allowMyFavouriteNotifications,
  isLocationPublic,
  isPublishPhotosAgreed,
  thirdPartyTracking,
  allowPersonalization,
  showRecentlyViewedItems,
}: UpdateCurrentUserSettingsArgs) => ({
  is_newsletter_subscriber: isNewsletterSubscribed,
  allow_my_favourite_notifications: allowMyFavouriteNotifications,
  is_location_public: isLocationPublic,
  is_publish_photos_agreed: isPublishPhotosAgreed,
  third_party_tracking: thirdPartyTracking,
  allow_personalization: allowPersonalization,
  show_recently_viewed_items: showRecentlyViewedItems,
})
