import { UpdateBusinessAccountProfileDetailsArgs } from 'types/api/arguments/update-business-account-profile-details-args'

export const updateBusinessAccountProfileDetailsArgsToParams = ({
  username,
  contactEmail,
  contactNumber,
  vat,
  about,
  photoTempUuid,
  isContactNumberPersonal,
  isContactEmailPersonal,
}: UpdateBusinessAccountProfileDetailsArgs) => ({
  name: username,
  email: contactEmail,
  phone_number: contactNumber,
  text_about: about,
  photo_temp_uuid: photoTempUuid,
  vat,
  personal_phone_number: isContactNumberPersonal,
  personal_email: isContactEmailPersonal,
})
