import { CreateDirectDonationOrderArgs } from 'types/api/arguments/create-direct-donation-order-args'

export const createDirectDonationOrderArgsToParams = ({
  charityCode,
  amount,
  currencyCode,
}: CreateDirectDonationOrderArgs) => {
  return {
    charity_code: charityCode,
    contribution: {
      amount,
      currency_code: currencyCode,
    },
  }
}
