import { isNil, omitBy } from 'lodash'

import { getSelectedDynamicFiltersArgs } from 'data/api/transformers/arguments/get-selected-dynamic-filters-args'
import { GetCatalogSearchedFiltersArgs } from 'types/api/arguments/get-catalog-searched-filters-args'

export const getCatalogSearchedFiltersArgsToParams = ({
  filterSearchCode,
  filterSearchText,
  query,
  catalogIds,
  priceFrom,
  priceTo,
  currency,
  sortBy,
  isPopularCatalog,
  catalogFrom,
  selectedDynamicFilters,
}: GetCatalogSearchedFiltersArgs) => {
  const staticArgs = {
    filter_search_code: filterSearchCode,
    filter_search_text: filterSearchText,
    search_text: query,
    catalog_ids: catalogIds.join(','),
    price_from: priceFrom,
    price_to: priceTo,
    currency,
    order: sortBy,
    popular: isPopularCatalog || null,
    catalog_from: catalogFrom,
  }

  return omitBy({ ...staticArgs, ...getSelectedDynamicFiltersArgs(selectedDynamicFilters) }, isNil)
}
