import { GetNearbyDropOffPointsArgs } from 'types/api/arguments/get-nearby-drop-off-points-args'

export const addNearbyDropOffPointArgsToParams = ({
  labelTypeId,
  countryCode,
  latitude,
  longitude,
  limit,
}: GetNearbyDropOffPointsArgs) => ({
  label_type_id: labelTypeId,
  country_code: countryCode,
  latitude,
  longitude,
  limit,
})
