import { SendItemUploadFeedbackArgs } from 'types/api/arguments/send-item-upload-feedback-args'

export const sendItemUploadFeedbackArgsToParams = ({
  rating,
  comment,
  finalized,
  itemId,
  feedbackStatementIds,
}: SendItemUploadFeedbackArgs) => ({
  item_id: itemId,
  rating,
  comment,
  finalized,
  feedback_statement_ids: feedbackStatementIds,
})
