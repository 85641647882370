import { SetBusinessAccountAddressesArgs } from 'types/api/arguments/set-business-account-addresses-args'

import type { BusinessAccountAddressesDto } from 'types/dtos/business-account'

import { transformBusinessAccountAddressModel } from 'data/transformers/business-account'

export const setBusinessAccountAddressesArgsToParams = ({
  businessAddress,
  returnAddress,
}: Pick<
  SetBusinessAccountAddressesArgs,
  'businessAddress' | 'returnAddress'
>): BusinessAccountAddressesDto => ({
  business_address: businessAddress && transformBusinessAccountAddressModel(businessAddress),
  return_address: returnAddress && transformBusinessAccountAddressModel(returnAddress),
})
