import { LoginUserArgs } from 'types/api/arguments/login-user-args'

export const authenticateUserArgsToParams = ({ grantType, ...loginUserArgs }: LoginUserArgs) => {
  if ('verificationCode' in loginUserArgs) {
    return {
      control_code: loginUserArgs.controlCode,
      password_type: loginUserArgs.passwordType,
      verification_code: loginUserArgs.verificationCode,
      grant_type: grantType,
      is_trusted_device: loginUserArgs.isTrustedDevice,
    }
  }

  if ('controlCode' in loginUserArgs) {
    return {
      control_code: loginUserArgs.controlCode,
      grant_type: grantType,
      username: loginUserArgs.username,
      password: loginUserArgs.password,
      fingerprint: loginUserArgs.fingerprint,
    }
  }

  const { ...args } = loginUserArgs

  return {
    ...args,
    grant_type: grantType,
  }
}
