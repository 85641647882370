import { BrowserAttributesModel } from 'types/models/browser-attributes'
import { BrowserAttributesDto } from 'types/dtos/browser-attributes'

export const transformBrowserAttributesModel = ({
  language,
  colorDepth,
  javaEnabled,
  screenWidth,
  screenHeight,
  timezoneOffset,
}: BrowserAttributesModel): BrowserAttributesDto => ({
  language,
  color_depth: colorDepth,
  java_enabled: javaEnabled,
  screen_height: screenHeight,
  screen_width: screenWidth,
  timezone_offset: timezoneOffset,
})
