import { CreateLiveChatTicketArgs } from 'types/api/arguments/create-live-chat-ticket-args'

export const createLiveChatTicketArgs = ({
  transactionId,
  ch,
  englishAllowed,
  message,
  helpCenterSessionId,
}: CreateLiveChatTicketArgs) => ({
  ch,
  transaction_id: transactionId,
  english_allowed: englishAllowed,
  message,
  help_center_session_id: helpCenterSessionId,
})
