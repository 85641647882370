export enum VerificationType {
  BankAccount = 'bank_account',
  Checkout = 'checkout',
  Login = 'login',
  Phone = 'phone',
  EntityHash = 'EntityHash',
}

export enum EmailCodeView {
  EnterCode = 'enterCode',
  NotReceiveEmail = 'notReceiveEmail',
}

export const TWO_FA_CANCELLED_EVENT = 'twoFACancelled'
export const TWO_FA_COMPLETED_EVENT = 'twoFACompleted'
