import { UpdateSingleCheckoutArgs } from '../types/checkout'

export const updateSingleCheckoutDataArgsToParams = ({
  paymentMethod,
  additionalService,
  shipping,
  shippingAddress,
}: UpdateSingleCheckoutArgs) => ({
  components: {
    additional_service: {
      is_selected: additionalService?.isSelected,
      type: additionalService?.type,
    },
    payment_method: {
      card_id: paymentMethod?.cardId,
      pay_in_method_id: paymentMethod?.methodId,
    },
    shipping_address: {
      user_id: shippingAddress?.userId,
      shipping_address_id: shippingAddress?.shippingAddressId,
    },
    shipping: {
      package_type_id: shipping?.packageTypeId,
      point_code: shipping?.pickupPointCode,
      point_uuid: shipping?.pointUuid,
      rate_uuid: shipping?.rateUuid,
      delivery_type: shipping?.deliveryType,
    },
  },
})
