import { isNil, omitBy } from 'lodash'

import { getSelectedDynamicFiltersArgs } from 'data/api/transformers/arguments/get-selected-dynamic-filters-args'
import { GetCatalogFiltersArgs } from 'types/api/arguments/get-catalog-filters-args'

export const getCatalogFiltersArgsToParams = ({
  query,
  catalogIds,
  priceFrom,
  priceTo,
  currency,
  sortBy,
  time,
  isPopularCatalog,
  catalogFrom,
  selectedDynamicFilters,
  filterSearchCode,
  filterSearchText,
}: GetCatalogFiltersArgs) => {
  const staticArgs = {
    search_text: query,
    catalog_ids: catalogIds.join(','),
    price_from: priceFrom,
    price_to: priceTo,
    currency,
    order: sortBy,
    time,
    popular: isPopularCatalog || null,
    catalog_from: catalogFrom,
    filter_search_code: filterSearchCode,
    filter_search_text: filterSearchText,
  }

  return omitBy({ ...staticArgs, ...getSelectedDynamicFiltersArgs(selectedDynamicFilters) }, isNil)
}
