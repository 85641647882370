import { isNil, omitBy } from 'lodash'

import { getSelectedDynamicFiltersArgs } from 'data/api/transformers/arguments/get-selected-dynamic-filters-args'
import { transformCatalogFiltersModelToParams } from 'data/api/transformers/arguments/transform-catalog-filters-model-to-params'
import { GetClosetPromotionArgs } from 'types/api/arguments/get-closet-promotion-args'

export const getClosetPromotionsArgsToParams = (args: GetClosetPromotionArgs) => {
  const staticArgs = {
    page: args.page,
    per_page: args.perPage,
    exclude_member_ids: args.excludedUserIds?.join(','),
    search_session_id: args.searchSessionId,
    screen_name: args.screenName,
    ...transformCatalogFiltersModelToParams(args),
  }

  return omitBy(
    { ...staticArgs, ...getSelectedDynamicFiltersArgs(args.selectedDynamicFilters) },
    isNil,
  )
}
