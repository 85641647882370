import { CreateComplaintArgs } from 'types/api/arguments/create-complaint-args'

export const getCreateComplaintArgs = ({
  faqEntryId,
  ch,
  problemTextId,
  transactionId,
  threadId,
  tempUuid,
  message,
  isEnglishAllowed,
  itemId,
  memberId,
  email,
  complaintOrderItems,
}: CreateComplaintArgs) => ({
  faq_entry_id: faqEntryId,
  ch,
  transaction_id: transactionId,
  thread_id: threadId,
  problem_text_id: problemTextId,
  temp_uuid: tempUuid,
  help_contact_form: {
    message,
    english_allowed: isEnglishAllowed,
    item_id: itemId,
    transaction_id: transactionId,
    member_id: memberId,
    email,
  },
  complaint_order_items: complaintOrderItems?.map(item => ({
    order_item_id: item.orderItemId,
    reason: item.code,
  })),
})
