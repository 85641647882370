import { CreateDsaNtdAppealArgs } from 'types/api/arguments/create-dsa-ntd-appeal-args'

export const getCreateDsaNtdAppealArgs = ({
  referenceId,
  message,
  isEnglishAllowed,
}: CreateDsaNtdAppealArgs) => ({
  reference_id: referenceId,
  message,
  english_allowed: isEnglishAllowed,
})
