export const MAX_INPUT_LENGTH = 255
export const MAX_INPUT_LENGTH_MEDIUM = 400
export const MAX_LINK_COUNT = 300
export const MAX_LINK_COUNT_LARGE = 150
export const MAX_LINK_COUNT_SMALL = 30

export enum RightsToOrganizationSelection {
  Owner = 'owner',
  Representor = 'representor',
  IntellectualProperty = 'intellectual_property',
  Individual = 'individual',
  TrustedFlagger = 'trusted_flagger',
  Organization = 'organization',
}

export enum RightsToOrganization {
  Owner = 'owner',
  Agent = 'agent',
  Lawyer = 'lawyer',
  IpOwner = 'intellectual_property_owner',
  IpAgent = 'intellectual_property_agent',
  IpLawyer = 'intellectual_property_lawyer',
  Individual = 'individual',
  TrustedFlagger = 'trusted_flagger',
  Organization = 'organization',
}

export enum ReportReasonType {
  IntellectualProperty = 'intellectual_property',
  OtherIllegal = 'other_illegal',
  Unsafe = 'unsafe',
}

export enum IntellectualPropertyCategory {
  Copyright = 'copyright',
  Trademark = 'trademark',
  Design = 'design',
  Patent = 'patent',
  Other = 'other',
}

export enum IllegalContentType {
  Item = 'item',
  User = 'user',
  Message = 'message',
  MessageFeedback = 'message_feedback',
}
