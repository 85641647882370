import { InitiateSingleCheckoutPaymentArgs } from 'types/api/arguments/initiate-single-checkout-payment-args'

import { transformBrowserAttributesModel } from 'data/transformers/browser-attributes'

export const singleCheckoutPaymentInitiationArgsToParams = ({
  cvv,
  checksum,
  blikCode,
  isSingleUseCard,
  browserAttributes,
}: InitiateSingleCheckoutPaymentArgs) => ({
  checksum,
  payment_options: {
    blik_code: blikCode,
    single_use_card: isSingleUseCard,
    ...(cvv ? { encrypted_card_details: { security_code: cvv } } : {}),
    browser_info: transformBrowserAttributesModel(browserAttributes),
  },
})
