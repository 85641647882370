import { SelectedDynamicFilterModel } from 'types/models/dynamic-filter'

export const getSelectedDynamicFiltersArgs = (
  selectedDynamicFilters?: Array<SelectedDynamicFilterModel>,
): Record<string, string> => {
  if (!selectedDynamicFilters) return {}

  const dynamicParams: Record<string, string> = {}

  selectedDynamicFilters.forEach(({ type, ids }) => {
    dynamicParams[`${type}_ids`] = ids.join(',')
  })

  return dynamicParams
}
