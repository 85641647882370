import { isNil, omitBy } from 'lodash'

import { GetNearbyShippingOptionsArgs } from 'types/api/arguments/get-nearby-shipping-options-args'

export const getNearbyShipmentOptionsArgsToParams = ({
  countryCode,
  latitude,
  longitude,
  addressLine,
  postalCode,
  city,
  limit,
  shouldLabelNearestPoints,
}: GetNearbyShippingOptionsArgs) =>
  omitBy(
    {
      country_code: countryCode,
      latitude,
      longitude,
      address_line: addressLine,
      postal_code: postalCode,
      city,
      limit,
      should_label_nearest_points: shouldLabelNearestPoints,
    },
    isNil,
  )
