import { AddressModel } from 'types/models/address'

export const addressToParams = (address: AddressModel | null) =>
  (address && {
    country_id: address.countryId,
    name: address.name || null,
    city: address.city || null,
    line1: address.line1 || null,
    line2: address.line2 || null,
    postal_code: address.postalCode || null,
  }) ||
  null
