import { AddBankAccountArgs } from 'types/api/arguments/add-bank-account-args'

export const addBankAccountArgsToParams = ({
  name,
  accountNumber,
  routingNumber,
  addressId,
  spendingType,
}: AddBankAccountArgs) => ({
  name,
  user_address_id: addressId,
  account_number: accountNumber,
  routing_number: routingNumber,
  spending_type: spendingType,
})
