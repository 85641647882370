import { InitiateEscrowPaymentArgs } from 'types/api/arguments/initiate-escrow-payment-args'

import { transformBrowserAttributesModel } from 'data/transformers/browser-attributes'

export const escrowPaymentInitiationArgsToParams = ({
  blikCode,
  isSingleUse,
  browserAttributes,
  transactionChecksum,
  encryptedCvv,
}: InitiateEscrowPaymentArgs) => ({
  blik_code: blikCode,
  single_use_card: isSingleUse,
  checksum: transactionChecksum,
  browser_attributes: transformBrowserAttributesModel(browserAttributes),
  encrypted_card_details:
    typeof encryptedCvv === 'string'
      ? {
          security_code: encryptedCvv,
        }
      : undefined,
})
