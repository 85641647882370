export enum OrderType {
  All = 'all',
  Sold = 'sold',
  Purchased = 'purchased',
}

export enum OrderStatus {
  All = 'all',
  InProgress = 'in_progress',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum MyOrderUserStatus {
  Failed = 'failed',
  NeedsAction = 'needs_action',
  Waiting = 'waiting',
  Completed = 'completed',
}
