import { FilterModel } from 'types/models/filter'

export const transformCatalogFiltersModelToParams = ({
  query,
  catalogIds,
  priceFrom,
  priceTo,
  currency,
  sortBy,
  isPopularCatalog,
  catalogFrom,
  disableSearchSaving,
}: Partial<FilterModel>) => ({
  search_text: query,
  catalog_ids: catalogIds?.join(','),
  price_from: priceFrom,
  price_to: priceTo,
  currency,
  order: sortBy,
  popular: isPopularCatalog || null,
  catalog_from: catalogFrom,
  disable_search_saving: disableSearchSaving,
})
