import { AdyenAuthAction } from 'types/adyen'
import { Native3DSParamDto, PaymentAuthActionDto } from 'types/dtos/payment'
import { Native3DSParamModel, PaymentAuthActionModel } from 'types/models/payment'

const isNestedActionResponse = (
  response?: AdyenAuthAction | { action: AdyenAuthAction },
): response is { action: AdyenAuthAction } => !!response && 'action' in response

export function transformPaymentAuthAction({
  adyen,
  adyen_bank,
}: PaymentAuthActionDto): PaymentAuthActionModel {
  return {
    adyen: isNestedActionResponse(adyen) ? adyen.action : adyen,
    adyenBank: isNestedActionResponse(adyen_bank) ? adyen_bank.action : adyen_bank,
  }
}

export function transformNative3DSParamModel(
  native3DSParam: Native3DSParamModel,
): Native3DSParamDto {
  return {
    authentication_id: native3DSParam.authenticationId,
    secure_3ds_details: {
      three_ds_result: native3DSParam.threeDSResult,
    },
  }
}
