import { RightsToOrganization } from 'constants/intellectual-property-infringement'
import { SendIntellectualPropertyInfringementArgs } from 'types/api/arguments/send-intellectual-property-infringement-args'

export const getContactDetails = ({
  rightsToOrganization,
  firstName,
  lastName,
  email,
  company,
  address,
  city,
  worldCountry,
  postalCode,
}: SendIntellectualPropertyInfringementArgs) => {
  switch (rightsToOrganization?.value) {
    case RightsToOrganization.Owner:
    case RightsToOrganization.Agent:
    case RightsToOrganization.Lawyer:
    case RightsToOrganization.IpOwner:
    case RightsToOrganization.IpAgent:
    case RightsToOrganization.IpLawyer:
    case RightsToOrganization.Organization:
    case RightsToOrganization.TrustedFlagger:
      return {
        first_name: firstName,
        last_name: lastName,
        email,
        company,
        address,
        city,
        country_code: worldCountry?.code,
        zip_code: postalCode,
      }
    case RightsToOrganization.Individual:
      return {
        first_name: firstName,
        last_name: lastName,
        email,
      }
    default:
      return {}
  }
}
