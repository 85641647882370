import { isNil, omitBy } from 'lodash'

import { getSelectedDynamicFiltersArgs } from 'data/api/transformers/arguments/get-selected-dynamic-filters-args'
import { transformCatalogFiltersModelToParams } from 'data/api/transformers/arguments/transform-catalog-filters-model-to-params'
import { GetCatalogItemsArgs } from 'types/api/arguments/get-catalog-items-args'

export const getCatalogItemsArgsToParams = (args: GetCatalogItemsArgs) => {
  const staticArgs = {
    page: args.page,
    per_page: args.perPage,
    time: args.time,
    search_session_id: args.searchSessionId,
    global_search_session_id: args.globalSearchSessionId || null,
    global_catalog_browse_session_id: args.globalCatalogBrowseSessionId || null,
    ...transformCatalogFiltersModelToParams(args),
  }

  return omitBy(
    { ...staticArgs, ...getSelectedDynamicFiltersArgs(args.selectedDynamicFilters) },
    isNil,
  )
}
