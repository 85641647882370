import { isNil, omitBy } from 'lodash'

import { CreateAdminAlertArgs } from 'types/api/arguments/create-admin-alert-args'

export const createAdminAlertArgsToParams = ({
  adminAlert: { refType, reportReasonId, refId, message, options },
}: CreateAdminAlertArgs) => {
  const getOptionParams = () => {
    return omitBy(
      {
        reported_message_id: options?.reportedMessageId,
        reported_photo_id: options?.reportedPhotoId,
      },
      isNil,
    )
  }

  return {
    admin_alert: omitBy(
      {
        ref_type: refType,
        ref_id: refId,
        report_reason_id: reportReasonId,
        message,
        options: options ? getOptionParams() : undefined,
      },
      isNil,
    ),
  }
}
