import { AddUserAddressArgs } from 'types/api/arguments/add-user-address-args'

export const addUserAddressArgsToParams = ({ address }: AddUserAddressArgs) => ({
  entry_type: address.entryType,
  country_id: address.countryId,
  name: address.name,
  country: address.country,
  city: address.city,
  line1: address.line1,
  line2: address.line2,
  postal_code: address.postalCode,
})
