export const suffixAsterisk = (text: string) => (
  <>
    {text} <span aria-hidden>*</span>
  </>
)

export const getItemsInText = (text: string | undefined) => {
  if (!text) return []

  return text.split(/[,|;| |\n]+/).filter(Boolean)
}
