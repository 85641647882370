import { transformNative3DSParamModel } from 'data/transformers/payment'
import { transformBrowserAttributesModel } from 'data/transformers/browser-attributes'
import { ContinueSingleCheckoutPaymentArgs } from 'types/api/arguments/continue-single-checkout-payment-args'

export const singleCheckoutPaymentContinueArgsToParams = ({
  cvv,
  checksum,
  blikCode,
  native3DSParam,
  isSingleUseCard,
  browserAttributes,
}: ContinueSingleCheckoutPaymentArgs) => ({
  checksum,
  payment_options: {
    blik_code: blikCode,
    single_use_card: isSingleUseCard,
    ...(cvv ? { encrypted_card_details: { security_code: cvv } } : {}),
    ...(native3DSParam ? { native_3ds_param: transformNative3DSParamModel(native3DSParam) } : {}),
    browser_info: transformBrowserAttributesModel(browserAttributes),
  },
})
