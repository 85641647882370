import { SendOfflineVerificationFeedbackArgs } from 'types/api/arguments/send-offline-verification-feedback-args'

export const sendOfflineVerificationFeedbackArgsToParams = ({
  rating,
  comment,
  finalized,
  feedbackStatementIds,
}: SendOfflineVerificationFeedbackArgs) => ({
  feedback_ratings: {
    rating,
    comment,
    finalized,
    feedback_statement_ids: feedbackStatementIds,
  },
})
