import { CreditCardEncryptedModel } from 'types/models/credit-card-encrypted'
import { CreditCardEncryptedDto } from 'types/dtos/credit-card-encrypted'

export const transformCreditCardEncryptedModel = ({
  cardNumber,
  expirationMonth,
  expirationYear,
  cvc,
}: CreditCardEncryptedModel): CreditCardEncryptedDto => ({
  number: cardNumber,
  expiration_month: expirationMonth,
  expiration_year: expirationYear,
  security_code: cvc,
})
