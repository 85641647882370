import { GetMyOrdersArgs } from 'types/api/arguments/get-my-orders-args'

import { OrderStatus } from 'constants/my-orders'

export const getMyOrdersArgsToParams = ({ type, status, perPage, page }: GetMyOrdersArgs) => ({
  type,
  status: status === OrderStatus.Cancelled ? 'canceled' : status,
  per_page: perPage,
  page,
})
