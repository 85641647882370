import { RightsToOrganization } from 'constants/intellectual-property-infringement'
import { getGenericReportParams } from 'data/api/transformers/arguments/get-generic-report-params'
import { getIpInfringementParams } from 'data/api/transformers/arguments/get-ip-infringement-params'
import { SendIntellectualPropertyInfringementArgs } from 'types/api/arguments/send-intellectual-property-infringement-args'

export const getPropertyRightsParams = (form: SendIntellectualPropertyInfringementArgs) => {
  switch (form.rightsToOrganization?.value) {
    case RightsToOrganization.Owner:
    case RightsToOrganization.Agent:
    case RightsToOrganization.Lawyer:
    case RightsToOrganization.IpOwner:
    case RightsToOrganization.IpAgent:
    case RightsToOrganization.IpLawyer:
      return getIpInfringementParams(form)
    case RightsToOrganization.Individual:
    case RightsToOrganization.Organization:
    case RightsToOrganization.TrustedFlagger:
      return getGenericReportParams(form)
    default:
      return {}
  }
}
