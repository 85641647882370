import { CreatePayoutArgs } from 'types/api/arguments/create-payout-args'

export const createPayoutArgsToParams = ({
  userId,
  bankAccountId,
  amount,
  currency,
}: CreatePayoutArgs) => ({
  payout: {
    seller_id: userId,
    bank_account_id: bankAccountId,
    amount,
    currency,
  },
})
