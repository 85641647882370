import {
  ApiClient,
  csrfTokenInterceptor,
  errorInterceptor,
  localeInterceptor,
} from '@marketplace-web/shared/api-client'
import { CookieHandler, cookiesDataByName } from '@marketplace-web/shared/cookies'
import {
  EMAIL_REGISTER_HEADERS,
  FACEBOOK_REGISTER_HEADERS,
  GOOGLE_REGISTER_HEADERS,
  APPLE_REGISTER_HEADERS,
} from '@marketplace-web/domain/script-detection'
import { GenerateRandomUserArgs } from 'types/api/arguments/generate-random-user-args'
import { LoginUserArgs } from 'types/api/arguments/login-user-args'
import { RegisterAppleUserArgs } from 'types/api/arguments/register-apple-user-args'
import { RegisterFacebookUserArgs } from 'types/api/arguments/register-facebook-user-args'
import { RegisterUserArgs } from 'types/api/arguments/register-user-args'
import { AuthenticateUserError } from 'types/api/response/authenticate-user-error'
import { RegisterAppleUserResponse } from 'types/api/response/register-apple-user-response'
import { RegisterFacebookUserResponse } from 'types/api/response/register-facebook-user-response'
import { RegisterGoogleUserResponse } from 'types/api/response/register-google-user-response'
import { RegisterUserResponse } from 'types/api/response/register-user-response'

import { authenticateUserArgsToParams } from 'data/api/transformers/arguments/authenticate-user-args-to-params'

const api = new ApiClient({ baseURL: '/web/api/auth' }, [
  csrfTokenInterceptor,
  errorInterceptor,
  localeInterceptor,
])

export const authenticateUser = async (args: LoginUserArgs) =>
  api.post<unknown, AuthenticateUserError>('/oauth', {
    client_id: 'web',
    scope: 'user',
    ...authenticateUserArgsToParams(args),
  })

export function registerUser(args: RegisterUserArgs) {
  const {
    realName,
    login,
    email,
    password,
    agreeRules,
    subscribeToNewsletter = false,
    fingerprint,
  } = args

  const user = {
    real_name: realName,
    login,
    email,
    password,
    agree_rules: agreeRules,
    user_settings: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<RegisterUserResponse>(
    '/email-register',
    {
      user,
      fingerprint,
    },
    { headers: EMAIL_REGISTER_HEADERS },
  )
}

export function registerFacebookUser(args: RegisterFacebookUserArgs) {
  const { token, login, email, subscribeToNewsletter = false, realName, fingerprint } = args

  const user = {
    login,
    real_name: realName,
    email,
    user_setting: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<RegisterFacebookUserResponse>(
    '/facebook-register',
    {
      fb_access_token: token,
      user,
      fingerprint,
    },
    { headers: FACEBOOK_REGISTER_HEADERS },
  )
}

export function registerGoogleUser(args: RegisterFacebookUserArgs) {
  const { token, login, email, subscribeToNewsletter = false, realName, fingerprint } = args

  const user = {
    login,
    real_name: realName,
    email,
    user_setting: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<RegisterGoogleUserResponse>(
    '/google-register',
    {
      id_token: token,
      user,
      fingerprint,
    },
    { headers: GOOGLE_REGISTER_HEADERS },
  )
}

export function registerAppleUser(args: RegisterAppleUserArgs) {
  const { token, login, email, subscribeToNewsletter = false, realName, fingerprint } = args

  const user = {
    login,
    real_name: realName,
    email,
    user_setting: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<RegisterAppleUserResponse>(
    '/apple-register',
    {
      id_token: token,
      user,
      fingerprint,
    },
    { headers: APPLE_REGISTER_HEADERS },
  )
}

export const refreshSessionTokens = async () => api.post<{ access_token: unknown }>('/refresh')

export const logoutUser = async ({ cookies }: { cookies: CookieHandler }) => {
  const response = await api.post('/logout')

  cookies.set(cookiesDataByName.last_user_id, '1')
  cookies.delete(cookiesDataByName.locale)

  return !('errors' in response)
}

export function generateRandomUser(args: GenerateRandomUserArgs) {
  const { isGod } = args

  return api.post('/generate-random-user', {
    is_god: isGod,
  })
}
