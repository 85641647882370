import { PaymentsIdentityFileModel } from '@marketplace-web/domain/payments-identity'

export const documentFileToParams = (file: PaymentsIdentityFileModel) => ({
  type: file.type,
  files: file.files.map(({ identifier, body, mediaType }) => ({
    identifier,
    body,
    media_type: mediaType,
  })),
})
