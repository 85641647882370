import { AuthenticateCreditCardArgs } from 'types/api/arguments/authenticate-credit-card-args'

export const authenticateCreditCardArgsToParams = ({
  paymentData,
  registrationId,
}: AuthenticateCreditCardArgs) => ({
  credit_card_registration: {
    id: registrationId,
  },
  payment_data: {
    ...paymentData,
  },
})
