import { getItemsInText } from 'pages/HelpCenter/IntellectualPropertyInfringement/utils'
import { SendIntellectualPropertyInfringementArgs } from 'types/api/arguments/send-intellectual-property-infringement-args'

export const getGenericReportParams = ({
  illegalContentType,
  reportReasonType,
  description,
  infringingUrlsTextArea,
  attachments,
}: SendIntellectualPropertyInfringementArgs) => ({
  illegal_content_type: illegalContentType,
  report_reason_code: reportReasonType,
  description,
  illegal_contents: getItemsInText(infringingUrlsTextArea).map(url => ({ url })),
  attachments: attachments?.map(attachment => ({ temp_uuid: attachment.id })),
})
