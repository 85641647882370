import { GetWardrobeItemsArgs } from 'types/api/arguments/get-wardrobe-items-args'

export const getWardrobeItemsArgsToParams = ({
  currentPage,
  perPage,
  order,
  filter,
  catalogIds,
  selectedItemId,
}: GetWardrobeItemsArgs) => ({
  page: currentPage + 1,
  per_page: perPage,
  cond: filter,
  order,
  catalog_ids: catalogIds?.join(','),
  selected_item_id: selectedItemId,
})
