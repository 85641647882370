import { SetBusinessAccountPoliciesArgs } from 'types/api/arguments/set-business-account-policies-args'

import type { BusinessAccountPoliciesDto } from 'types/dtos/business-account'

export const setBusinessAccountPoliciesArgsToParams = ({
  termsAndConditions,
  returnPolicy,
  additionalInformation,
}: SetBusinessAccountPoliciesArgs): BusinessAccountPoliciesDto => ({
  terms_and_conditions: termsAndConditions,
  return_policy: returnPolicy,
  additional_information: additionalInformation,
})
