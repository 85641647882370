import { withServerSideProxy } from '@marketplace-web/shared/ssr'

import { serverLog } from './server-logging'

import { Log } from './type'

const log = {
  logMessage: (...args) => window.apphealth?.captureMessage(...args),
  logWarning: (...args) => window.apphealth?.captureWarning(...args),
  logError: (...args) => window.apphealth?.captureError(...args),
} satisfies Log

export const { logMessage, logWarning, logError } = withServerSideProxy(log, serverLog)
