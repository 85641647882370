import { getContactDetails } from 'data/api/transformers/arguments/get-contact-details'
import { getPropertyRightsParams } from 'data/api/transformers/arguments/get-property-rights-params'
import { SendIntellectualPropertyInfringementArgs } from 'types/api/arguments/send-intellectual-property-infringement-args'

export const sendIntellectualPropertyInfringementArgsToParams = (
  form: SendIntellectualPropertyInfringementArgs,
) => {
  return {
    rights_to_organization: form.rightsToOrganization?.value,
    contacts: getContactDetails(form),
    property_rights: getPropertyRightsParams(form),
  }
}
